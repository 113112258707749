// src/App.js
import React, { useState } from "react";
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import { Container, Box, TextField, Button, Typography, Paper, Snackbar, Alert } from '@mui/material';
import { styled } from '@mui/system';
import axios from "axios";
import './index.css';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  margin: 'auto',
  maxWidth: 600,
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

const StyledLogo = styled('img')(({ theme }) => ({
  width: '200px',
  marginBottom: theme.spacing(2),
  transition: 'width 0.5s, height 0.5s',
  [theme.breakpoints.up('md')]: {
    width: '300px',
  },
}));

function App() {
  const [url, setUrl] = useState("");
  const [shortUrl, setShortUrl] = useState("");
  const [validUrl, setValidUrl] = useState(false);
  const [copied, setCopied] = useState(false);
  const [open, setOpen] = useState(false);

  const urlRegex = /^([-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*))$/;

  const handleUrlChange = (e) => {
    const inputUrl = e.target.value;
    setUrl(inputUrl);
    setValidUrl(urlRegex.test(inputUrl));
  };

  const formatUrl = (inputUrl) => {
    if (!/^https?:\/\//i.test(inputUrl)) {
      return `http://${inputUrl}`;
    }
    return inputUrl;
  };

  const shortenUrl = async (e) => {
    e.preventDefault();
    if (!validUrl) {
      alert("Please enter a valid URL");
      return;
    }
    try {
      const formattedUrl = formatUrl(url);
      const response = await axios.post("https://ltlnow.co/shorten", { url: formattedUrl });
      setShortUrl(response.data.shortUrl);
      setCopied(false);
    } catch (error) {
      alert("Something went wrong. Please check the URL or try again later.");
      console.log(error);
    }
  };

  const reset = () => {
    setUrl("");
    setShortUrl("");
    setValidUrl(false);
    setCopied(false);
  };

  const handleCopyClick = async () => {
    if ("clipboard" in navigator) {
      await navigator.clipboard.writeText(shortUrl);
    } else {
      document.execCommand("copy", true, shortUrl);
    }
    setCopied(true);
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <StyledPaper elevation={3}>
          <StyledLogo alt="logo" src="logo.png" />
          <Typography variant="h5" gutterBottom>
            URL Shortener
          </Typography>
          {!shortUrl && (
            <form onSubmit={shortenUrl}>
              <TextField
                label="Enter a URL to shorten"
                variant="outlined"
                fullWidth
                value={url}
                onChange={handleUrlChange}
                margin="normal"
              />
              <StyledButton
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
              >
                Submit
              </StyledButton>
            </form>
          )}
          {shortUrl && (
            <Box>
              <Typography
                variant="h6"
                component="span"
                onClick={handleCopyClick}
                sx={{ cursor: 'pointer', color: copied ? 'green' : 'black' }}
              >
                {copied ? "Copied!" : shortUrl}
              </Typography>
              <Typography variant="body2" sx={{ color: 'gray' }}>
                Click to Copy
              </Typography>
              <StyledButton
                variant="contained"
                color="secondary"
                fullWidth
                onClick={reset}
              >
                Reset
              </StyledButton>
            </Box>
          )}
          <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
              URL copied to clipboard!
            </Alert>
          </Snackbar>
        </StyledPaper>
      </Container>
    </ThemeProvider>
  );
}

export default App;
